import { appConfig } from "~/utils/config";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            productStructuredData: {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "image": [
                        `${ this.getProductHeroImagesList().join("\",\"") }`
                    ],
                    "name": `${ this.productData?.displayName }`,
                    "sku": `${ this.productData?.article }`,
                    "color": `${ this.productData?.color }`,
                    "description": `${ this.productData?.description?.subHeadline }`,
                    "brand": {
                        "@type": "Brand",
                        "name": `"adidas ${ this.productData?.division }"`
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": `${ this.productData?.url?.absolute }`,
                        "itemCondition": "https://schema.org/NewCondition",
                        "availability": "https://schema.org/InStock",
                        "price": `${ this.productData?.price?.sale }`,
                        "priceCurrency": `${ appConfig?.mainInfo?.currencyName }`,
                        "shippingDetails": {
                            "@type": "DefinedRegion",
                            "addressCountry": "KZ",
                            "businessDays": {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "https://schema.org/Monday",
                                    "https://schema.org/Tuesday",
                                    "https://schema.org/Wednesday",
                                    "https://schema.org/Thursday",
                                    "https://schema.org/Friday",
                                    "https://schema.org/Saturday",
                                    "https://schema.org/Sunday"
                                ],
                                "opens": `${ appConfig?.mainInfo?.openUTCTime?.from }`,
                                "closes": `${ appConfig?.mainInfo?.openUTCTime?.to }`,
                            }
                        }
                    },
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            "productData": "productView/getProduct"
        }),
        imagesFullListUrls() {
            if (this.productData?.imagesFullList && this.productData?.imagesFullList.length) {
                return this.productData?.imagesFullList.map(imageItem => {
                    const imageSizes = ImageHelper.getMediaStructure(
                        imageItem.id,
                        "jpg",
                        imageItem.title,
                        imageItem.alt,
                        ImageSizes.catalogMain,
                        60
                    );
                    imageItem.simpleUrl = imageSizes?.src?.desktop?.["1x"];
                    return imageItem;
                });
            }
            return [];
        },
    },
    methods: {
        getProductHeroImagesList() {
            return this.imagesFullListUrls && this.imagesFullListUrls.length ?
                this.imagesFullListUrls.map(item => item.simpleUrl) :
                [];
        }
    },
};
